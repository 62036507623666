import { defineStore } from 'pinia'
import axios from "axios";
import { useLoading } from 'vue-loading-overlay';
const $loading = useLoading();

export const useAuthStore = defineStore('auth', {
  state: () => {
    return { 
        autenticado: localStorage.getItem("auth_token") ? true : false,
        user: null,
    }
  },
  actions: {

    async login(user) {
        const response = (await axios.post("auth/login", user)).data;
        if (response.status == 'ok') {
          const token = response.token;
          localStorage.setItem('auth_token',token)
          return response
        }else{
          return response
        }
      },
  
      async logout() {
          let loader = $loading.show();
          localStorage.removeItem('auth_token');
          this.autenticado  = !this.autenticado
          loader.hide()
      },
  
      async getUser() {
        try {
          const token = localStorage.getItem('auth_token')
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          this.user = (await axios.get("auth")).data;
          this.autenticado = true;
        } catch (error) {
          localStorage.removeItem('auth_token')
          window.location ='login'
          this.user = null;
          this.autenticado = false;
        }
      },


      async update(userData) {
        const response = (await axios.put("auth", userData)).data;
        if (response.status == 'ok') {
          await this.getUser();
          return response
        }else{
          return response
        }
      },
  

  }, 
})