import { ref } from 'vue'
import axios from 'axios'

export function getTiposEnvios(){
  let tipos_envios = [
    {id: 0, envio: 'Aereo'},
    {id: 1, envio: 'Maritimo'},
  ]
  return tipos_envios
}

export function getTimeActual(){
    var fecha = new Date(); 
    var hora = fecha.getHours();
    var actualTime = '';
   
    if(hora >= 0 && hora < 12){
        actualTime = "Buenos días";
    }
   
    if(hora >= 12 && hora < 18){
        actualTime = "Buenas tardes";
    }
   
    if(hora >= 18 && hora < 24){
        actualTime = "Buenas noches";
    }
    return {actualTime}
  }


  export function getDateChartsActual(){
    var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    var d = new Date();
    var mesActual = months[d.getMonth()];
    var yearActual = d.getFullYear();
    var dateChartActual = yearActual + "-" + mesActual
    return {dateChartActual}
  }


/************ Funciones api Paises */
  export async function getPaises(){
    let response = await axios.get('/paises')
    return response.data
  }


  export async function getEstados(pais){
    let response = await axios.get(`/paises/estados/${pais}`)
    return response.data
  }


  export async function getCiudades(estado){
    let response = await axios.get(`/paises/ciudades/${estado}`)
    return response.data
  }
  /************** Fin funciones api Paises */


  /********** Funciones api clientes / destinatarios  */

  export async function getDocumentosClientes(documento){
    let response = await axios.get(`/clientes?search=filter&q=${documento}`)
    return response.data
  }

  export async function getClienteByDocumento(documento){
    let response = await axios.get(`/clientes?search=documento&q=${documento}`)
    return response.data
  }


  export async function getClienteById(id){
    let response = await axios.get(`/clientes?search=id&q=${id}`)
    return response.data
  }


  export async function getClientesDestinatarios(id_cliente){
    let response = await axios.get(`/clientes-destinatarios/by-cliente/${id_cliente}`)
    return response.data
  }

  /**********Fin Funciones api clientes  */


  /***** Tipos de documentos ***/

  export function tiposDocumentos(){
    let tipos_documentos = [
      {id: 1, tipo: 'pasaporte'},
      {id: 2, tipo: 'cedula'},
      {id: 3, tipo: 'cedula extranjera'},
      {id: 4, tipo: 'dni'},
      {id: 5, tipo: 'ruc'},
      {id: 6, tipo: 'carnet extranjeria'},
      {id: 7, tipo: 'ptp'},
      {id: 8, tipo: 'carnet refugiado'},
      {id: 9, tipo: 'rif'},
      {id: 10, tipo: 'otros'},
    ]
    return {tipos_documentos}
  }

  /***********Fin tipo de documentos********** */


  
  /***** Tipos de documentos ***/

  export function divisaPais(){
    let divisas = [
      {
        id: 1, 
        pais: 'venezuela',
        divisas: [{id: 1, divisa: 'Bolivares', simbolo: 'Bs'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 2, 
        pais: 'peru',
        divisas:[{id: 1, divisa: 'Soles', simbolo: 'S/.'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 3, 
        pais: 'chile',
        divisas:[{id: 1, divisa: 'Pesos Chilenos', simbolo: '$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 4, 
        pais: 'ecuador',
        divisas: [{id: 1, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 5, 
        pais: 'colombia',
        divisas: [{id: 1, divisa: 'Pesos Colombianos', simbolo: '$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 6, 
        pais: 'argentina',
        divisas: [{id: 1, divisa: 'Pesos Argentinos', simbolo: '$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 7, 
        pais: 'bolivia',
        divisas: [{id: 1, divisa: 'Bolivianos', simbolo: 'B$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 8, 
        pais: 'brasil',
        divisas: [{id: 1, divisa: 'Reals', simbolo: 'R$'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 9, 
        pais: 'spain',
        divisas: [{id: 1, divisa: 'Euros', simbolo: '€'}, {id: 2, divisa: 'Dolares', simbolo: '$'}],
      },
      {
        id: 10, 
        pais: 'united states',
        divisas: [{id: 1, divisa: 'Dolares', simbolo: '$'}],
      },
    ]
    return divisas
  }


  export function dataDivisaByPais(pais){
    const divisas = divisaPais()
    let divisaFilter = divisas.filter(item => item.pais == pais.toLowerCase())[0]
    return divisaFilter
  }

  /***********Fin tipo de documentos********** */


  /********** Medios de pago */
 
  export function getMediosPago(){
    let metodos_pago = 
    [
      {id:1, status: 1, metodo: 'Efectivo'},
      {id:2, status: 1, metodo: 'Transferencia'},
      {id:3, status: 1, metodo: 'Deposito'},
      {id:4, status: 1, metodo: 'Agora'},
      {id:5, status: 1, metodo: 'Yape'},
      {id:6, status: 1, metodo: 'Plin'},
      {id:7, status: 0, metodo: 'Tunki'},
    ]
    return metodos_pago
  }

  /********** Fin Medios de pago */


  /********** Funcion Api Tasas Cambios  */
      export async function getTasasCambio(search){
        try{
          let response = (await axios.get(`/tasa-cambio`)).data
          return response
        }catch(error){
          return []
        }
      }
      /**************** Fin Api Articulos */


    /********** Funcion Api Articulos  */
    export async function getArticulos(search){
      try{
        let response = await axios.get(`/articulos-historial/${search}`)
        return response.data
      }catch(error){
        return []
      }
    }
    /**************** Fin Api Articulos */


  /********** Funcion Trackings  */
 export function getTrackingList(){
    let list_tracking = [
      {id: 1, tracking: 'Creacion', descripcion: 'Paquete recepcionado en oficina'},
      {id: 3, tracking: 'Recibido', descripcion: 'Tu paquete fue recibido en nuestros almacenes / sedes.'},
      {id: 4, tracking: 'Almacén', descripcion: 'Su paquete ha sido envalijado en {{PAIS_ORIGEN}}, y en pronto será enviado a su destino {{PAIS_DESTINO}}.'},
      {id: 5, tracking: 'Aduana origen', descripcion: 'Su paquete ha sido entregado a la aduana en {{PAIS_ORIGEN}} donde se le cargarán los aranceles de salida del país o aranceles. '},
      {id: 6, tracking: 'En Transito', descripcion: 'Su paquete paquete se encuentra en translado al destino en {{PAIS_DESTINO}}'},
      {id: 7, tracking: 'Almacén ADT', descripcion: 'Mercancía no despachada a la espera de recibir el transbordo hacia {{PAIS_DESTINO}}.'},
      {id: 8, tracking: 'Aduana destino', descripcion: 'Su paquete ha llegado a la aduana en {{PAIS_DESTINO}} donde se le cargarán los aranceles de ingreso al país y será tramitado para el envío al Centro de Distribución'},
      {id: 9, tracking: 'Recibido en Distribución', descripcion: 'El paquete se encuentra en el Centro de Distribución para su envío a la Sucursal seleccionada.'},
      {id: 10, tracking: 'Transferido', descripcion: 'Su paquete fue enviado a la sucursal de retiro. '},
      {id: 11, tracking: 'Disponible', descripcion: 'Ya puedes retirar tu paquete.'},
    ]
    return list_tracking
}


export function getTrackingListById(id){
  const list_tracking = getTrackingList()
  let trackingFilter = list_tracking.filter(item => item.id === id)[0]
  return trackingFilter
}
/**************** Fin trackings */


/********* Couriers Api */

export async function getCouriers (id){
  let response = (await axios.get('courier')).data
  return response
}


export async function getCouriersOffices(id_courier){
  let response = (await axios.get('courier-oficinas/' + id_courier)).data
  return response
}

/***************** */


/****** Guias Api */
export async function getGuiaById(id){
  let response = (await axios.get('guias/detail-guia/' + id)).data
  return response.guias
}


export async function getGuideById(id){
  let response = (await axios.get('guias/' + id)).data
  return response
}


export async function validateGuidesManifest(guides){
  let response = (await axios.post('cargas/validate-guides', { guides: guides})).data
  return response
}
/******************/


/********** Manifiestos Api */
export async function getGuiasOffice(){
  let response = (await axios.get('manifiestos/get-guias')).data
  return response.guias
}

export async function getManifiestoById(id){
  let response = (await axios.get(`manifiestos/${id}`)).data
  return response
}

export async function getGuiasByIdManifiesto(id){
  let response = (await axios.get(`manifiestos/guias/${id}`)).data
  return response.guias
}
/******************/


/********** Cargas Api */
export async function getManifiestosOffice(){
  let response = (await axios.get('cargas/get-manifiestos')).data
  return response.manifiestos
}

export async function getCargaById(id){
  let response = (await axios.get(`cargas/${id}`)).data
  return response
}
/******************/