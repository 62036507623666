import {
  createRouter,
  createWebHistory,
} from "vue-router";
import { useAuthStore } from "../store/auth";
import {useLoading} from 'vue-loading-overlay'
const $loading = useLoading();
const appName = "Encomiendas App";

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

function lazyLoadComponents(view) {
  return () => import(`@/components/${view}.vue`);
}


const redirectHome = (to, from, next) => {
  const token = localStorage.getItem("auth_token") ? true : false;
  if (useAuthStore().autenticado || token) {
    next("/");
  } else {
    next();
  }
};

const checkAuth = async (to, from, next) => {
  const token = localStorage.getItem("auth_token") ? true : false;
  await useAuthStore().getUser()
  if (!useAuthStore().autenticado || !token) {
    window.location = "/login";
  } else {
    next();
  }
};


const rolAccess = (...roles) => async (to, from, next) => {
  const token = localStorage.getItem("auth_token") ? true : false;
  if (!useAuthStore().autenticado || !token) {
      window.location = "/login"
  } else {
    await useAuthStore().getUser()
    const rol = useAuthStore().user.rol

    if((roles).includes(rol)){
      next();
    }else{
      window.location = "/"
    }

  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: lazyLoad("MainHome"),
    meta: { layout: "autenticado", title: `Inicio - ${appName}` },
    beforeEnter: checkAuth,
  },

  {
    path: "/oficinas",
    name: "Oficinas",
    component: lazyLoad("Oficinas/Oficinas"),
    meta: { layout: "autenticado", title: `Oficinas - ${appName}` },
    beforeEnter: rolAccess('admin','agente'),
  },
  {
    path: "/oficinas/:id(\\d+)/edit",
    name: "Oficina Edit",
    component: lazyLoad("Oficinas/Oficina-Edit"),
    meta: { layout: "autenticado", title: `Editando Oficina - ${appName}` },
    beforeEnter: rolAccess('admin','agente'),
  },


  {
    path: "/courier",
    name: "Courier",
    component: lazyLoad("Courier/Courier"),
    meta: { layout: "autenticado", title: `Courier - ${appName}` },
    beforeEnter: rolAccess('admin','agente'),
  },

  {
    path: "/categorias",
    name: "Categorias",
    component: lazyLoad("Categorias/Categorias"),
    meta: { layout: "autenticado", title: `Categorias - ${appName}` },
    beforeEnter: rolAccess('admin','agente'),
  },
  {
    path: "/categorias/:id(\\d+)/edit",
    name: "Categoria Edit",
    component: lazyLoad("Categorias/Categoria-Edit"),
    meta: { layout: "autenticado", title: `Editando Categoria - ${appName}` },
    beforeEnter: rolAccess('admin','agente'),
  },


  {
    path: "/sub-categorias",
    name: "Sub-Categorias",
    component: lazyLoad("Categorias/Sub-Categorias/Sub-Categorias"),
    meta: { layout: "autenticado", title: `Sub Categorias - ${appName}` },
    beforeEnter: rolAccess('admin','agente'),
  },
  {
    path: "/sub-categorias/:id(\\d+)/edit",
    name: "Sub Categoria Edit",
    component: lazyLoad("Categorias/Sub-Categorias/Sub-Categoria-Edit"),
    meta: { layout: "autenticado", title: `Editando Sub Categoria - ${appName}` },
    beforeEnter: rolAccess('admin','agente'),
  },



  {
    path: "/guias-aereas",
    name: "Guias-Aereas",
    component: lazyLoad("Guias/Aerea/index"),
    meta: { layout: "autenticado", title: `Guias Aereas - ${appName}` },
    beforeEnter: checkAuth,
  },
  {
    path: "/guias-aereas/nueva",
    name: "Nueva-Guia-Aerea",
    component: lazyLoadComponents("guias/Wizard-Nueva-Guia"),
    meta: { layout: "autenticado", title: `Nueva Guia Aerea - ${appName}` },
    beforeEnter: checkAuth,
  },
  {
    path: "/guias-aereas/:id(\\d+)/edit",
    name: "Editacion-Guia-Aerea",
    component: lazyLoadComponents("guias/Guia-Edit"),
    meta: { layout: "autenticado", title: `Editando Guia Aerea - ${appName}` },
    beforeEnter: checkAuth,
  },



  {
    path: "/guias-maritimas",
    name: "Guias-Maritimas",
    component: lazyLoad("Guias/Maritima/index"),
    meta: { layout: "autenticado", title: `Guias Maritimas - ${appName}` },
    beforeEnter: checkAuth,
  },
  {
    path: "/guias-maritimas/nueva",
    name: "Nueva-Guia-Maritima",
    component: lazyLoadComponents("guias/Wizard-Nueva-Guia"),
    meta: { layout: "autenticado", title: `Nueva Guia Maritima - ${appName}` },
    beforeEnter: checkAuth,
  },
  {
    path: "/guias-maritimas/:id(\\d+)/edit",
    name: "Editacion-Guia-Maritima",
    component: lazyLoadComponents("guias/Guia-Edit"),
    meta: { layout: "autenticado", title: `Editando Guia Maritima - ${appName}` },
    beforeEnter: checkAuth,
  },


  {
    path: "/manifiestos",
    name: "Manifiestos",
    component: lazyLoad("Guias/Manifiestos/index"),
    meta: { layout: "autenticado", title: `Manifiestos - ${appName}` },
    beforeEnter: checkAuth,
  },
  {
    path: "/manifiestos/:id(\\d+)/edit",
    name: "Editacion-Manifiestos",
    component: lazyLoad("Guias/Manifiestos/Edit"),
    meta: { layout: "autenticado", title: `Editando Manifiestos - ${appName}` },
    beforeEnter: checkAuth,
  },
  
  {
    path: "/cargas",
    name: "Cargas",
    component: lazyLoad("Guias/Cargas/index"),
    meta: { layout: "autenticado", title: `Cargas - ${appName}` },
    beforeEnter: rolAccess('admin'),
  },
  {
    path: "/cargas/nueva",
    name: "Cargas-Nueva",
    component: lazyLoad("Guias/Cargas/NuevaAndEdit"),
    meta: { layout: "autenticado", title: `Nueva Carga - ${appName}` },
    beforeEnter: rolAccess('admin'),
  },
  {
    path: "/cargas/:id(\\d+)/edit",
    name: "Editacion-Carga",
    component: lazyLoad("Guias/Cargas/NuevaAndEdit"),
    meta: { layout: "autenticado", title: `Editando Carga - ${appName}` },
    beforeEnter: rolAccess('admin'),
  },


  {
    path: "/perfil",
    name: "Perfil",
    component: lazyLoad("Perfil"),
    meta: { layout: "autenticado", title: `Perfil - ${appName}` },
    beforeEnter: checkAuth,
  },

  {
    path: "/clientes",
    name: "Cientes",
    component: lazyLoad("Clientes/Clientes"),
    meta: { layout: "autenticado", title: `Clientes - ${appName}` },
    beforeEnter: rolAccess('admin'),
  },
  {
    path: "/clientes/:id(\\d+)/edit",
    name: "Ciente Edit",
    component: lazyLoad("Clientes/Cliente-Edit"),
    meta: { layout: "autenticado", title: `Editando Cliente - ${appName}` },
    beforeEnter: rolAccess('admin'),
  },

  {
    path: "/usuarios",
    name: "Usuarios",
    component: lazyLoad("Usuarios/Usuarios"),
    meta: { layout: "autenticado", title: `Usuarios - ${appName}` },
    beforeEnter: rolAccess('admin'),
  },
  {
    path: "/usuarios/:id(\\d+)/edit",
    name: "Usuario Edit",
    component: lazyLoad("Usuarios/Usuario-Edit"),
    meta: { layout: "autenticado", title: `Editando Usuario - ${appName}` },
    beforeEnter: rolAccess('admin'),
  },

  {
    path: "/configuracion",
    name: "Configuracion",
    component: lazyLoad("Configuracion"),
    meta: { layout: "autenticado", title: `Configuracion - ${appName}` },
    beforeEnter: rolAccess('admin','agente'),
  },

  {
    path: "/login",
    name: "login",
    component: lazyLoad("Login"),
    meta: { layout: "default", title: `Login - ${appName}` },
    beforeEnter: redirectHome,
  },
  {
    path: "/sin-acceso",
    meta: { layout: "default", title: "401 - Sin Permisos" },
    component: lazyLoad("Sin-Acceso"),
  },
  {
    path: "/:pathMatch(.*)",
    meta: { layout: "default", title: "404 - Pagina no encontrada" },
    component: lazyLoad("PageNotFound"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



router.beforeResolve((to, from, next) => {
  NProgress.start()
  document.title = `${to.meta.title}`;
  next()
});


router.afterEach((to, from) => {
  NProgress.done()
})

export default router;
