<template>
    <Modal :titulo="`Calculadora Envios`" @close="toggleModal" :modalActive="true">
        
    <div class="content-calculadora p-3" style="height: 400px; overflow-y:scroll;">
    <div class="row g-3">
     

      <div class="col-md-6">
        <input type="radio" value="0" id="inputModoAereo" v-model="calculadora.modo" @change="getTasasEnvios">
        <label for="inputModoAereo" class="ms-1">Calculo Aéreo</label>
      </div>

      <div class="col-md-6">
        <input type="radio" value="1" id="inputModoMaritimo" v-model="calculadora.modo" @change="getTasasEnvios">
        <label for="inputModoMaritimo" class="ms-1">Calculo Marítimo</label>
      </div>

       <div class="col-md-12">
        <div v-if="loadingTasasEnvios" class="animate b-radius" style="height: 60px;"></div>
        <div v-else  class="form-floating mb-3 input-promociones animate__animated animate__fadeIn">
          <select class="form-select fw-bold" v-model="calculadora.tasa_selected" @change="setTasa">
          <option v-for="item in calculadora.tasas" :key="item.id" :value="item.id">{{item.nombre}} <span>({{item.divisa_simbolo + item.tasa}})</span></option>
          </select>
          <label for="floatingSelect">Tasa Encomienda</label>
      </div>
     </div>


     <div class="col-4" v-if="calculadora.tasa_selected">
      <div class="d-flex flex-column">
      <img class="d-flex m-auto" style="width: 80px; height: 80px" src="@/assets/img/recursos/box_alto.png" alt="Medida Alto">
      <input type="number" v-model="calculadora.medidas.alto" class="form-control text-center" placeholder="Alto" autocomplete="off" min="1" oninput="this.value = Math.abs(this.value)">
      </div>
  </div>


<div class="col-4" v-if="calculadora.tasa_selected">
      <div class="d-flex flex-column">
      <img class="d-flex m-auto" style="width: 80px; height: 80px" src="@/assets/img/recursos/box_ancho.png" alt="Medida Ancho">
      <input type="number" v-model="calculadora.medidas.ancho" class="form-control text-center" placeholder="Ancho" autocomplete="off" min="1" oninput="this.value = Math.abs(this.value)">
      </div>
  </div>



  <div class="col-4" v-if="calculadora.tasa_selected">
      <div class="d-flex flex-column">
      <img class="d-flex m-auto" style="width: 80px; height: 80px" src="@/assets/img/recursos/box_largo.png" alt="Medida Largo">
      <input type="number" v-model="calculadora.medidas.largo" class="form-control text-center" placeholder="Largo" autocomplete="off" min="1" oninput="this.value = Math.abs(this.value)">
      </div>
</div>


<div class="col-md-12" v-if="calculadora.medidas.peso_volumetrico">
  <div class="d-flex flex-md-row flex-column justify-content-between">
    <h5>Peso Volumen: {{calculadora.medidas.peso_volumetrico.toFixed(3)}}</h5>
    <h5>Peso Redondeado: {{parseFloat(Math.ceil(calculadora.medidas.peso_volumetrico)).toFixed(3)}}</h5>
  </div>

  <div class="d-flex flex-md-row flex-column justify-content-between">
    <h5 v-if="calculadora.modo == 1">M3: {{calculadora.medidas.m3.toFixed(3)}}</h5>
    <h5 v-if="calculadora.modo == 1">Pie Cubico: {{calculadora.medidas.ft3.toFixed(3)}}</h5>
 
  </div>
 
</div>
     
  
       <div class="col-md-12 position-relative" v-if="calculadora.medidas.alto && calculadora.medidas.ancho && calculadora.medidas.largo">
            <div class="form-floating mb-3">
                <input
                v-model="calculadora.peso"
                type="number"
                :min="1"
                class="form-control fs-xl-2 b-radius text-center"
                />
                <label class="text-center" for="floatingInput">Peso Encomienda</label>
            </div>
            <i class="fa-solid fa-scale-unbalanced-flip icon-calculadora"></i>

      </div>




        <div class="col-md-12" v-if="calculadora.medidas.alto && calculadora.medidas.ancho && calculadora.medidas.largo && calculadora.costo">
            <h3 class="mb-3">Costo del envío</h3>
            <h5>{{calculadora.tasa.divisa_simbolo + parseFloat(calculadora.costo).toFixed(2)}}</h5>
        </div>
 
      
   </div> 
  </div>
    </Modal>
</template>

<script setup>
import axios from 'axios'
import Modal from '@/components/modals/Modal.vue'
import {defineEmits, reactive, ref, onMounted, computed} from 'vue'
import {} from '@/helpers'

const emit = defineEmits(['toggleModal'])

const calculadora = reactive({
    modo: 0,
    medidas: {
      alto: '',
      ancho: '',
      largo: '',
      peso_volumetrico: computed(() => parseFloat(calculadora.medidas.alto * calculadora.medidas.ancho * calculadora.medidas.largo) / 5000),
      m3: computed(() => parseFloat(calculadora.medidas.alto * calculadora.medidas.ancho * calculadora.medidas.largo) / 1000000),
      ft3: computed(() => parseFloat(calculadora.medidas.m3) * 35.315)
    },
    tasas: [],
    tasa_selected: '',
    tasa: '',
    divisas: [],
    tasa: '',
    peso: '',
    costo: computed(() => 
    calculadora.modo == 0 ? 

    calculadora.tasa.promocion == 1 ? calculadora.tasa.tasa : 

    (Math.ceil(calculadora.medidas.peso_volumetrico) > calculadora.peso ? 
    parseFloat(calculadora.tasa.tasa * Math.ceil(calculadora.medidas.peso_volumetrico)) : 
    parseFloat(calculadora.tasa.tasa * calculadora.peso) ) : 
    
    calculadora.modo == 1 ? 


    calculadora.tasa.promocion == 1 ? calculadora.tasa.tasa : 



    calculadora.tasa.calculado == 0 ? Math.ceil(calculadora.medidas.peso_volumetrico) > calculadora.peso ? 
    parseFloat(calculadora.tasa.tasa * Math.ceil(calculadora.medidas.peso_volumetrico)) : 
    parseFloat(calculadora.tasa.tasa * calculadora.peso) :
    
    (calculadora.tasa.calculado == 1 ?  
    parseFloat(calculadora.tasa.tasa * calculadora.medidas.m3.toFixed(3)) : '') : ''
    )
})



const loadingTasasEnvios = ref()
const getTasasEnvios = async () => {
    let pais = 'peru'
    loadingTasasEnvios.value = true
    let response = (await axios.get(`/tasa-envios/pais/${pais}?tg=${calculadora.modo}`)).data
    loadingTasasEnvios.value = false
    if(response.length){
    calculadora.tasas = response
    }else{
      calculadora.tasas = []   
    }
}


const setTasa = (e) => {
  calculadora.tasa = calculadora.tasas.filter(item => item.id == calculadora.tasa_selected)[0]
}

const calcularEnvio = () => {
  /*if(calculadora.tasa){

    let pVolumetrico = Math.ceil(parseFloat(calculadora.medidas.alto * calculadora.medidas.ancho * calculadora.medidas.largo) / 5000)
    if( parseFloat(pVolumetrico) > parseFloat(calculadora.peso) ) {
        calculadora.costo = parseFloat(filter.tasa * pVolumetrico).toFixed(3)
      }else{
        calculadora.costo = parseFloat(filter.tasa * calculadora.peso)
    }
  }*/
}

const toggleModal = () => {
    emit('toggleModal', false)
}


onMounted(async () => {
  getTasasEnvios()
})
</script>

<style scoped>
.icon-calculadora{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 3rem;
    padding: 0.8rem;
    font-size: 2rem;
}
</style>