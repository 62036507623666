<template>
<div>
    <Modal :titulo="`Tracking Guias`" @close="toggleModal" :modalActive="true">
        
     <div class="row">

       <div class="col-md-6 position-relative">
       <div class="form-floating mb-3">
                <input
                :disabled="loading"
                v-on:keyup.enter="getTrackingsByGuia"
                v-model="guia"
                type="number"
                class="form-control fs-xl-2 b-radius text-center"
                placeholder="Guia Courier"
                required
                />
                <label class="text-center" for="floatingInput">NRO GUIA</label>
            </div>
            <i class="fas fa-search icon-search" @click="getTrackingsByGuia"></i>

            </div>

        <div class="col-md-6 position-relative">
         <div class="form-floating mb-3">
              <select class="form-select fw-bold" v-model="tracking_select" :disabled="loading">
                <option v-for="(item, index) in trackingList" :key="item.id" :value="item.id">({{index+1}}) {{item.tracking}}</option>
              </select>
              <label for="floatingSelect">Lista De Trackings</label>
        </div>

         <i v-if="trackingsGuia.length > 0" class="fa-solid fa-arrows-rotate icon-refresh" @click="getTrackingsByGuia" title="Recargar listado de trackings"></i>

        <i v-if="tracking_select" class="fa-solid fa-circle-plus icon-add" @click="setTrackingsGuia"></i>

        </div>

     </div>


    <div v-if="!loading && trackingsGuia.length == 0">
        <h3 class="text-uppercase mb-3">Guia Sin Tracking</h3>
        <div class="col-md-12">
            <img class="img-empty-tracking" src="@/assets/img/recursos/tracking_empty.svg" alt="guia_sin_tracking">
        </div>
    </div>

      <div v-if="loading || trackingsGuia.length > 0" class="h-scroll" style="position:relative; top: 1rem;">

          <div v-if="loading">
           <div class="d-flex" v-for="item in 5" :key="item">

                <div class="d-flex flex-column">
                <span class="number-consult-guia PicLoaderCircular animate"></span>
                <span class="separador-number-guia-loading comment br animate"></span>
                </div>


                <div class="ms-3 d-flex flex-column w-100">
                    <div class="fecha d-flex justify-content-between">
                        <span class="comment br animate" style="width: 80px; height: 20px;"></span>
                        <span class="comment br animate" style="width: 80px; height: 20px;"></span>
                    </div>
                    <span class="text-primary fw-bold text-center mt-2 comment br animate m-auto" style="width: 280px; height: 15px;"></span>
                    <span class="text-muted comment br animate" style="height: 15px;"></span>

                    <span class="text-muted comment br animate" style="height: 4px;"></span>
                </div>

            </div>
          </div>


            <div v-else class="d-flex" v-for="(item, index) in trackingsGuia" :key="item">
                <div class="d-flex flex-column">
                <span class="number-consult-guia">{{index+1}}</span>
                <span class="separador-number-guia"></span>
                </div>

                <div class="ms-3 d-flex flex-column w-100">
                    <div class="fecha d-flex justify-content-between">
                        <span>{{new Date(item.fecha).toLocaleDateString("en-GB")}}</span>
                        <span>{{new Date(item.fecha).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric"
                        }) }}</span>
                    </div>
                    <span class="text-primary fw-bold text-center mt-2 mb-2">
                        {{item.estado}}
                        <i v-if="item.estado !== 'Creacion' && $auth.user.rol == 'admin'" 
                        class="fa-regular fa-trash-can g-trash c-pointer" @click="toggleConfirmGuiaDelete(item.id, item.estado)"></i>
                        </span>
                    <span class="text-muted">{{item.descripcion}}</span>
                    <div class="hr-border-bottom text-secundary"></div>
                </div>

            </div>

      </div>
 
    </Modal>


    <ModalConfirm  @close="toggleConfirmGuiaDelete" :modalActive="modalActiveConfirmDelete">
      <h1 class="text-danger">ADVERTENCIA</h1>
      <h5 class="text-uppercase">¿Estas seguro de eliminar el tracking ({{tracking_asignado}}) asignado a la guia <b>{{guia}}</b> de manera permanente?</h5>
       <div class="buttons-modal-footer">
          <button @click="toggleConfirmGuiaDelete" type="button" class="bg-dark text-white">Cancelar</button>
          <button @click="deleteTracking" type="button" class="btn-modal-crear bg-danger">Si, Seguro</button>
      </div>
      </ModalConfirm>
</div>
</template>

<script setup>
import ModalConfirm from '@/components/modals/Modal-Confirm.vue'
import axios from 'axios'
import Modal from '@/components/modals/Modal.vue'
import {defineEmits, onMounted, ref} from 'vue'
import {getTrackingList, getTrackingListById} from '@/helpers'


import { useToast } from "vue-toastification";
const toast = useToast();

const emit = defineEmits(['toggleModal'])

const guia = ref('')

const tracking_select = ref('')

const trackingsGuia = ref([])

const trackingList = ref([])

const loading = ref(false)

const id_registro = ref('')

const tracking_asignado = ref('')

const modalActiveConfirmDelete = ref(false)

const toggleModal = () => {
    emit('toggleModal', false)
};


onMounted(async () => {
    trackingList.value = getTrackingList()
})


const toggleConfirmGuiaDelete = (id, tracking) => {
 id_registro.value = id
 tracking_asignado.value = tracking
 modalActiveConfirmDelete.value = !modalActiveConfirmDelete.value
}

const getTrackingsByGuia = async () => {
    loading.value = !loading.value
    let data = {guia: guia.value}
    let response = (await axios.post(`/tracking/status`, data)).data
    loading.value = !loading.value
    if(response.status == 'ok'){
    trackingsGuia.value = response.tracking
    }else{
    trackingsGuia.value = []  
    }
}


const setTrackingsGuia = async () => {
    loading.value = !loading.value
    let tracking = getTrackingListById(tracking_select.value)
    let guiaObj = {guia: guia.value, tracking: tracking}
    let response = (await axios.post('/tracking', guiaObj)).data
    loading.value = !loading.value
    if(response.status == 'ok'){
    getTrackingsByGuia()
    }else if(response.status == 'existe'){
    toast.info(response.mensaje)
    }else if(response.status == 'error'){
    toast.error(response.mensaje)  
    }else{
    toast.error('No se pudo ingresar tracking a la guia')     
    }
}


const deleteTracking = async () => {
    loading.value = !loading.value
    let response = (await axios.delete(`/tracking/${id_registro.value}`)).data
    loading.value = !loading.value
    if(response.status == 'ok'){
    getTrackingsByGuia()
    toggleConfirmGuiaDelete()
    toast.success(response.mensaje)  
    }else if(response.status == 'error'){
    toast.error(response.mensaje)  
    }else{
    toast.error('No se pudo eliminar el tracking a la guia')     
    }
}
</script>

<style scoped>
.icon-search{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 2rem;
    padding: 0.8rem;
    font-size: 2rem;
}


.icon-add{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 2rem;
    padding: 0.8rem;
    font-size: 2rem;
}

.icon-refresh{
    cursor: pointer;
    position: absolute;
    top: -3.5rem;
    right: 3.5rem;
    font-size: 2rem;
    transition: 0.4s all;
}

.icon-refresh:hover{
    color: var(--primary-alt)
}

.g-trash{
    margin-left: 0.3rem;
    color: #dc3545;
    transition: 0.3s all;
}

.g-trash:hover{
    transform: scale(1.2);
}

.img-empty-tracking{
    width: 100%;
    height: 250px;
}

@media (max-width: 768px) {
    .icon-refresh{
        top: -8.2rem;
    }
    
}
</style>