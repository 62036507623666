<template>
  <footer class="app-footer" v-if="$auth.autenticado">
    <hr />
    <div class="d-flex justify-content-between">
      <p class="mb-0 text-uppercase color-principal">© copyright 2022</p>
      <a class="autor-link" href="mailto:angelogml@gmail.com">By AGML</a>
    </div>
  </footer>
</template>

<style scoped>
.app-footer {
  transition: 0.3s ease-in-out;
  position: absolute;
  bottom: -5rem;
  width: 100%;
  padding: 0 0 1rem 0;
  color: var(--primary)
}

.autor-link{
  color: var(--primary)!important;
  transition: 0.3s all;
}


.autor-link:hover{
  transform: scale(1.2);
  color: var(--dark-alt)!important;
}

@media (max-width: 991px) {
  .app-footer {
    padding: 1rem !important;
  }
}
</style>

